import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import Card from "components/layouts/Card";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  z-index: 50;
  background: rgba(0, 0, 0, 0.3);

  ${({ $dark }) =>
    $dark &&
    css`
      background: rgba(0, 0, 0, 0.5);
    `}
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 120px;
`;
const CardBlock = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-width: 33.333%;
  min-width: 300px;
  min-height: 200px;
  padding: 15px 25px;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px lightgray;
  margin: auto;
`;
const BlockWrapperDisable = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  ${({ size }) =>
    size &&
    css`
      width: ${size};
      height: ${size};
    `}

  & .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;

    ${({ theme }) => css`
      stroke: ${theme.colors["cl-main"] || "gray"};
    `}
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export function SpinnerWrapper(props) {
  return (
    <Wrapper {...props}>
      <Spinner />
    </Wrapper>
  );
}

export function SpinnerBlockWrapper() {
  return (
    <BlockWrapperDisable>
      <Spinner />
    </BlockWrapperDisable>
  );
}

export function SpinnerBlock({ message, card }) {
  return card ? (
    <CardBlock>
      {message && message.toString()}
      <Spinner />
    </CardBlock>
  ) : (
    <Block>
      {message && message.toString()}
      <Spinner />
    </Block>
  );
}

export function Spinner({ size }) {
  return (
    <StyledSpinner size={size} viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
    </StyledSpinner>
  );
}

Spinner.propTypes = {
  size: PropTypes.string,
};

SpinnerBlock.propTypes = {
  message: PropTypes.string,
  card: PropTypes.bool,
};
