import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { selectLoading, selectMembershipBlocked } from "_store/orders/selector";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const BlockedLayout = () => {
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const isMembershipBlocked = useSelector(selectMembershipBlocked);

  useEffect(() => {
    if (!loading && !isMembershipBlocked) {
      navigate("/");
    }
  }, [loading, isMembershipBlocked]);

  return (
    <Container>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

export default BlockedLayout;
