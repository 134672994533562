import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";

import { phoneCleaner, validateTrim } from "_helpers";

import PhoneField from "components/atomic/molecules/fields/PhoneField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

// const FieldsGroupHeader = styled.div`
//   text-align: center;
//   margin: 0 0 24px 0;
// `;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > * {
    flex: 1;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

// const ButtonsGroup = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   gap: 24px;
// `;

const rules = {
  firstName: () => ({
    required: "First Name is a required field",
    pattern: {
      value: /^[ a-zA-Z\\&-]+$/i,
      message: "First Name should contain only letters and & \\ - symbols",
    },
    maxLength: {
      value: 30,
      message: "First Name should be less or equal 30 symbols",
    },
    validate: (value) => {
      if (value?.includes("null")) {
        return 'First Name can\'t contain "Null" name';
      }

      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      return true;
    },
  }),
  lastName: () => ({
    required: "Last Name is a required field",
    pattern: {
      value: /^[ a-zA-Z\\&-]+$/i,
      message: "Last Name should contain only letters and & \\ - symbols",
    },
    maxLength: {
      value: 30,
      message: "Last Name should be less or equal 30 symbols",
    },
    validate: (value) => {
      if (value?.includes("null")) {
        return 'Last Name can\'t contain "Null" name';
      }

      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      return true;
    },
  }),
  phone: () => ({
    required: "Phone is a required field",
    validate: (value) => {
      if (value?.includes("_")) {
        return "You must provide a valid phone number";
      }

      return true;
    },
  }),
  email: () => ({
    required: "Email is a required field",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid Email address",
    },
    maxLength: {
      value: 100,
      message: "Email should be less or equal 100 symbols",
    },
  }),
};

const initValues = {
  newManager: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  },
};

// eslint-disable-next-line react/display-name
const AmendmentManagerChangeForm = forwardRef((props, ref) => {
  const { handlers } = props;
  const { onSubmit } = handlers;

  const form = useForm({ defaultValues: initValues, reValidateMode: "onChange" });
  const {
    control,
    formState: { errors },
    handleSubmit: createSubmitHandler,
  } = form;

  const handleSubmit = createSubmitHandler((data) => {
    const newData = {
      newManager: {
        ...data.newManager,
        phone: phoneCleaner(data?.newManager?.phone),
      },
    };
    onSubmit(newData);
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: () => handleSubmit(),
    }),
    [handleSubmit]
  );

  return (
    <>
      <StyledForm ref={ref} onSubmit={handleSubmit}>
        <FieldsGroup>
          <p>New Manager Details</p>
          <FieldsGroupContent>
            <TextField
              column
              name="newManager.firstName"
              label="First Name"
              placeholder="First Name..."
              control={control}
              errors={errors}
              rules={rules.firstName()}
            />
            <TextField
              column
              name="newManager.lastName"
              label="Last Name"
              placeholder="Last Name..."
              control={control}
              errors={errors}
              rules={rules.lastName()}
            />
          </FieldsGroupContent>
          <FieldsGroupContent>
            <TextField
              column
              name="newManager.email"
              label="Email"
              placeholder="Email..."
              control={control}
              errors={errors}
              rules={rules.email()}
            />
            <PhoneField
              column
              label="Phone"
              placeholder="Phone..."
              name="newManager.phone"
              control={control}
              errors={errors}
              rules={rules.phone()}
            />
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>
    </>
  );
});

export default AmendmentManagerChangeForm;

AmendmentManagerChangeForm.propTypes = {
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
};
