import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import styled, { css } from "styled-components";

import { handleProductName } from "_helpers";
import { getStatusColor, handleCustomerStatus } from "_helpers/profile";
import {
  // selectCompanyAltName,
  // selectprimaryOfficerFullName,
  // selectOwnerTitle,
  selectCompanyName,
  selectCorpAddress,
  selectCorpAddressObj,
  selectCrmOrderId,
  selectDBA,
  selectDissolutionProduct,
  selectIncorporatedDate,
  selectMembers,
  selectNumberOfMembers,
  selectOrderDate,
  selectOrganizedState,
  selectPrimaryOfficerFullName,
  selectProcessingError,
  selectProcessingErrorsObj,
  selectProcessingStatus,
  selectProcessingStatusesObj,
  selectProductName,
  selectStatesObj,
} from "_store/orders/selector";

import colors from "components/Theme/colors";
import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import CompanyInfoItem from "components/atomic/molecules/CompanyInfoItem";
import DissolveOrderModal from "components/atomic/organisms/modals/DissolveOrderModal";
import ErrorDetailsModal from "components/atomic/organisms/modals/ErrorDetailsModal";
import MembersListModal from "components/atomic/organisms/modals/MembersListModal";
import RequestAmendmentModal from "components/atomic/organisms/modals/RequestAmendmentModal";
import Card from "components/layouts/Card";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 30px;
  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    width: 100%;
    max-width: 700px;
  }
`;

const SystemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  min-width: 280px;

  @media (max-width: 991px) {
    width: 100%;
    max-width: 700px;
  }
`;

const SystemItemsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 991px) {
    flex-direction: row;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CompanyItemsList = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  padding: 32px 20px;

  @media (max-width: 991px) {
    gap: 16px;
  }
`;

const ChangeInfo = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 102px;
  padding: 32px 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  @media (max-width: 991px) {
    text-align: center;
    gap: 12px;
    height: auto;
    flex-direction: column;
    margin-bottom: 48px;
  }
`;

const DissolveBusiness = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 136px;
  gap: 18px;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 0px;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-black"]};
  `}

  @media (max-width: 991px) {
    flex-direction: row;
    height: auto;
    padding: 12px;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    height: auto;
  }
`;

const CardTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const SystemInfoCard = styled(Card)`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 16px;

  @media (max-width: 991px) {
    min-width: 220px;
    max-width: 220px;

    justify-content: center;
  }

  @media (max-width: 760px) {
    min-width: auto;
    max-width: 100%;
  }
`;

const SystemInfoMain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

// const SystemInfoExtra = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-top: 12px;
//   padding: 12px 0;

//   ${({ theme }) => css`
//     border-top: 2px solid ${theme.colors["cl-card-border"]};
//   `}
// `;

const SystemInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 150px;
  width: 100%;
`;

const SystemInfoValue = styled.div`
  display: flex;
  gap: 4px;
  font-size: 1.25rem;
  font-weight: 500;

  ${({ theme, color }) => css`
    color: ${color || theme.colors["cl-text-dark"]};
  `}
`;

const InfoIcon = styled(Icon)`
  cursor: pointer;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.1);
  }
`;

const ActionButton = styled(Button)`
  padding: 2px;
  margin-left: 8px;
  height: 23px;
  font-size: 12px;
`;
const DateIncorporated = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.875rem;
  opacity: 70%;
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;

const ProfilePage = () => {
  const themeContext = useTheme();

  const [showMembersList, setShowMembersList] = useState(false);
  const [showErrorDetails, setShowErrorDetails] = useState(false);
  const [showRequestAmendment, setShowRequestAmendment] = useState(false);
  const [showDissolveOrder, setShowDissolveOrder] = useState(false);

  const processingStatus = useSelector(selectProcessingStatus);
  const processingError = useSelector(selectProcessingError);
  const crmOrderId = useSelector(selectCrmOrderId);
  const orderDate = useSelector(selectOrderDate);
  const companyName = useSelector(selectCompanyName);
  // const companyAltName = useSelector(selectCompanyAltName);
  const dba = useSelector(selectDBA);
  const organizedState = useSelector(selectOrganizedState);
  const primaryOfficerFullName = useSelector(selectPrimaryOfficerFullName);
  // const ownerTitle = useSelector(selectOwnerTitle);
  const corpAddress = useSelector(selectCorpAddress);
  const corpAddressObj = useSelector(selectCorpAddressObj);
  const productName = useSelector(selectProductName);
  const numberOfMembers = useSelector(selectNumberOfMembers);
  const members = useSelector(selectMembers);
  const processingStatusesObj = useSelector(selectProcessingStatusesObj);
  const processingErrorsObj = useSelector(selectProcessingErrorsObj);
  const statesObj = useSelector(selectStatesObj);
  const incorporatedDate = useSelector(selectIncorporatedDate);
  const dissolutionProduct = useSelector(selectDissolutionProduct);

  const displayedStatus = handleCustomerStatus(processingStatus);

  const canDissolving = () => {
    if (!dissolutionProduct) {
      return true;
    }

    if (dissolutionProduct && dissolutionProduct?.processingResult?.status === "Lead") {
      return true;
    }

    return false;
  };

  const statusColor = useMemo(() => {
    if (!displayedStatus) {
      return themeContext.colors["cl-text-dark"];
    }

    return getStatusColor(displayedStatus);
  }, [displayedStatus, themeContext]);

  return (
    <>
      <Container>
        <CompanyInfoContainer>
          <CompanyItemsList>
            <DateIncorporated>
              {incorporatedDate
                ? `Date Incorporated: ${moment(incorporatedDate).format("MM/DD/YYYY")}`
                : "Not Incorporated yet"}
            </DateIncorporated>
            <CompanyInfoItem title="Business Name" value={companyName || "N/A"} />
            {/* <CompanyInfoItem title="Alt. Business Name" value={companyAltName || "N/A"} /> */}
            <CompanyInfoItem title="DBA" value={dba || "N/A"} />
            <CompanyInfoItem
              title="Organized State"
              value={statesObj[organizedState] ? `${statesObj[organizedState]} (${organizedState})` : "N/A"}
            />
            <CompanyInfoItem noWordBreak title="Primary Officer" value={primaryOfficerFullName || "N/A"} />
            <CompanyInfoItem title="Physical Address" value={corpAddress || "N/A"} address={corpAddressObj} />
            <CompanyInfoItem title="Type of Business" value={handleProductName(productName) || "N/A"} />
            <CompanyInfoItem
              title="# of Members"
              value={numberOfMembers || "N/A"}
              actions={
                members?.length ? (
                  <ActionButton outlined onClick={() => setShowMembersList(true)}>
                    View All
                  </ActionButton>
                ) : null
              }
            />
            <CompanyInfoItem title="Tax Classification" value={"N/A"} />
          </CompanyItemsList>
          <ChangeInfo>
            Need to make changes to this Information?
            <Button
              outlined
              minwidth="200px"
              width="200px"
              onClick={() => {
                setShowRequestAmendment(true);
              }}
            >
              File an amendment
            </Button>
          </ChangeInfo>
        </CompanyInfoContainer>
        <SystemInfoContainer>
          <SystemItemsList>
            <SystemInfoCard>
              <SystemInfoMain>
                <Icon icon="profile:status" color={statusColor} padding="10px" size="44px" rounded />
                <SystemInfoContent>
                  <CardTitle>Status</CardTitle>
                  <SystemInfoValue color={statusColor}>
                    {processingStatusesObj[displayedStatus]?.statusName}
                    {processingError && (
                      <InfoIcon
                        title="Show error details"
                        icon="misc:info"
                        color={colors["cl-text-light"]}
                        size="24px"
                        onClick={() => setShowErrorDetails((prev) => !prev)}
                      />
                    )}
                  </SystemInfoValue>
                </SystemInfoContent>
              </SystemInfoMain>
            </SystemInfoCard>

            <SystemInfoCard>
              <SystemInfoMain>
                <Icon icon="profile:date" color={themeContext.colors["cl-item2"]} padding="10px" size="44px" rounded />
                <SystemInfoContent>
                  <CardTitle>Order Received</CardTitle>
                  <SystemInfoValue>{orderDate ? moment(orderDate).format("MM/DD/YYYY") : "N/A"}</SystemInfoValue>
                </SystemInfoContent>
              </SystemInfoMain>
            </SystemInfoCard>

            <SystemInfoCard>
              <SystemInfoMain>
                <Icon icon="profile:order" color={themeContext.colors["cl-item3"]} padding="10px" size="44px" rounded />
                <SystemInfoContent>
                  <CardTitle>Order #</CardTitle>
                  <SystemInfoValue>{crmOrderId || "N/A"}</SystemInfoValue>
                </SystemInfoContent>
              </SystemInfoMain>
            </SystemInfoCard>
          </SystemItemsList>
          {canDissolving() && (
            <DissolveBusiness>
              Need to Close Business?
              <Button primary minwidth="180px" onClick={() => setShowDissolveOrder(true)}>
                Dissolve Business
              </Button>
            </DissolveBusiness>
          )}
        </SystemInfoContainer>
      </Container>
      {showRequestAmendment && (
        <RequestAmendmentModal
          onCancel={() => {
            setShowRequestAmendment(false);
          }}
        />
      )}
      {showMembersList && (
        <MembersListModal
          members={members}
          onCancel={() => {
            setShowMembersList(false);
          }}
        />
      )}
      {showErrorDetails && (
        <ErrorDetailsModal
          errorDetails={processingErrorsObj[processingError]}
          onCancel={() => {
            setShowErrorDetails(false);
          }}
        />
      )}
      {showDissolveOrder && (
        <DissolveOrderModal
          onCancel={() => {
            setShowDissolveOrder(false);
          }}
        />
      )}
    </>
  );
};

export default ProfilePage;
