import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { PRODUCT_CODES } from "_constants/products";
import { UPDATE_REQUIRED_CODES } from "_constants/variables";
import { handleError } from "_helpers";
import { OrdersService } from "_services";
import { requestUpdate } from "_store/application/slice";
import { selectActiveOrderId } from "_store/orders/selector";

import book from "assets/img/business_license_doc.svg";

import Button from "components/atomic/atoms/Button";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(8px);
`;
const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 48px 80px;
  margin: 0 12px;
  min-width: 33.333%;

  ${({ theme }) => css`
    border: 3px solid ${theme.colors["cl-secondary"]};
  `}

  @media (max-width: 991px) {
    padding: 48px 32px;
  }

  animation: fadeIn 0.5s;
  ${({ theme }) => theme.animations.fadeIn}
`;
const CardContent = styled.div`
  width: 100%;
  height: 100%;
`;
const StyledCardHeader = styled.div`
  margin-bottom: 12px;
  font-size: 1.125rem;
  text-align: center;
`;
const StyledCardBody = styled.div`
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 32px;
`;
const HeaderRow = styled.div``;
const TextRow = styled.div`
  margin-bottom: 12px;
`;
const StyledCardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled(Button)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  ${({ theme }) => css`
    background: ${theme.colors["cl-active-button-bg"]};
  `}
`;
const BackgroundImage = styled.img`
  position: absolute;
  bottom: -24px;
  left: 4px;
  width: 200px;
  height: 200px;
  filter: drop-shadow(-3px 3px 3px rgb(0 0 0 / 0.4));

  @media (max-width: 991px) {
    top: -100px;
    right: calc(50% - 110px);
    left: unset;
    width: 170px;
    height: 170px;
  }
`;

const BusinessLicenseOverlay = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [step, setStep] = useState("stepStart");

  const orderId = useSelector(selectActiveOrderId);

  async function createBusinessLicenseUpsale(orderId, body) {
    try {
      const request = await OrdersService.createDelayedUpsell({ orderId, body });

      if (request?.status === 200) {
        if (request?.data?.products[0]?.paymentStatus === "Declined") {
          setStep("stepError");
          setError({ message: "Card Declined. Please, check your current payment method from Billing page." });
        } else {
          // setStep("stepSuccess");
          dispatch(requestUpdate(UPDATE_REQUIRED_CODES.incStatesBusinessLicenses));
        }
      } else {
        setStep("stepError");
        setError({ message: "Unknown Error" });
      }
    } catch (response) {
      setStep("stepError");
      setError(handleError(response));
    }
  }

  switch (step) {
    case "stepStart":
      return (
        <Container>
          <StyledCard>
            <BackgroundImage src={book} />
            <CardContent>
              <StyledCardHeader>
                <HeaderRow>You may need business licenses & permits for your business</HeaderRow>
                <HeaderRow>Do you need help finding them?</HeaderRow>
              </StyledCardHeader>
              <StyledCardBody>
                <TextRow>
                  We can help you get the right licenses & permits for just <b>$99</b>
                </TextRow>
              </StyledCardBody>
              <StyledCardFooter>
                <StyledButton
                  primary
                  onClick={() => {
                    const body = {
                      order: { processingOption: "UPSELL", campaignId: 28 },
                      products: [{ code: PRODUCT_CODES.incStatesBusinessLicenses }],
                    };
                    setStep("stepLoading");
                    createBusinessLicenseUpsale(orderId, body);
                  }}
                >
                  Yes, help me locate them
                </StyledButton>
              </StyledCardFooter>
            </CardContent>
          </StyledCard>
        </Container>
      );

    case "stepLoading":
      return (
        <Container>
          <StyledCard>
            <CardContent>
              <SpinnerBlock message="Sending Request" />
            </CardContent>
          </StyledCard>
        </Container>
      );

    case "stepError":
      return (
        <Container>
          <StyledCard>
            <CardContent>
              <StyledCardHeader>
                <HeaderRow>Please, try again later or contact with our agents.</HeaderRow>
              </StyledCardHeader>
              <StyledCardBody>
                <TextRow>{error?.message ? `Error: ${error?.message}` : ""}</TextRow>
              </StyledCardBody>
              <StyledCardFooter>
                <StyledButton
                  primary
                  onClick={() => {
                    setError(null);
                    setStep("stepStart");
                  }}
                >
                  Cancel and try again
                </StyledButton>
              </StyledCardFooter>
            </CardContent>
          </StyledCard>
        </Container>
      );

    default:
      break;
  }
};

export default BusinessLicenseOverlay;
