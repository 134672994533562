import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { config } from "_config";

import CustomLink from "components/atomic/atoms/CustomLink";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 200px; */
  width: 100%;
  max-width: 600px;
  /* transform: translate(-100%, -50%); */
  font-size: 1rem;
  /* height: 32px; */
  /* padding: 4px; */
  /* margin: 8px 42px; */
  /* border-radius: 4px; */
  border: 2px solid;
  border-radius: 4px 4px 0 0;
  background-color: #ffe3e3;
  z-index: 1000;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}

  ${({ theme }) => css`
    /* background-color: ${theme.colors["cl-error"]}; */
    border-color: ${theme.colors["cl-error"]};
  `}

  @media(max-width: 760px) {
    max-width: 100%;
    border-radius: 0;
  }
`;

const MembershipBlockedAlert = () => {
  const [show, setShow] = useState(false);
  const currentUrl = useLocation().pathname;
  const isBlockedPage = currentUrl.includes("/blocked");

  useEffect(() => {
    setShow(!isBlockedPage);
  }, [currentUrl]);

  return (
    <Container hide={!show}>
      <CustomLink to={config.publicUrl + `/blocked`}>Your membership is blocked, click for details</CustomLink>
    </Container>
  );
};

export default MembershipBlockedAlert;
