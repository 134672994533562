import React, { useState } from "react";
import { error as errorNotif } from "react-notification-system-redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { selectLoading, selectResetPasswordStatus } from "_store/user/selector";
import { resetPassword } from "_store/user/slice";

import logo from "assets/img/logos/logo-dark.svg";

import Button from "components/atomic/atoms/Button";
import Input from "components/atomic/atoms/Input";
import { SpinnerWrapper } from "components/atomic/atoms/Spinner";
import ResetPasswordSuccessModal from "components/atomic/organisms/modals/ResetPasswordSuccessModal";
import Card from "components/layouts/Card";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 16px;
  max-width: 33.333%;
  min-width: 300px;
  min-height: 200px;
  padding: 15px 25px;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px lightgray;
  margin: auto;
  font-size: 1.125rem;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 84px;
  max-height: 84px;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;

  ${({ theme }) => css`
    color: ${theme.colors["cl-main"]};
  `}

  img {
    width: 52px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1120px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
`;

const StyledButton = styled(Button)`
  min-width: 160px;
`;

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [request, setRequest] = useState(false);

  const resetPasswordStatus = useSelector(selectResetPasswordStatus);
  const loading = useSelector(selectLoading);

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (email) {
      if (emailRegex.test(email)) {
        dispatch(resetPassword(email));
        setRequest(true);
      } else {
        dispatch(errorNotif({ title: "Error", message: "Enter valid email addess in format 'name@example.com'" }));
      }
    }
  };

  return (
    <>
      <StyledContainer>
        <StyledCard>
          <StyledLogo>
            <img src={logo} />
            &nbsp;State&nbsp;Filings
          </StyledLogo>
          <StyledForm onSubmit={handleSubmit}>
            <span>
              Forgot your password? <br />
              Enter your e-mail below for us to send you a reset code
            </span>
            <Input
              required
              placeholder="Email"
              type="email"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />

            <Buttons>
              <StyledButton
                borderless
                onClick={(e) => {
                  e.preventDefault();
                  navigate("../login", { replace: true });
                }}
              >
                Return to Log In
              </StyledButton>
              <StyledButton primary type="submit">
                Reset
              </StyledButton>
            </Buttons>
          </StyledForm>
        </StyledCard>
      </StyledContainer>
      {loading && <SpinnerWrapper />}
      {request && resetPasswordStatus && (
        <ResetPasswordSuccessModal
          onCancel={() => {
            setRequest(false);
          }}
        />
      )}
    </>
  );
};

export default ForgotPasswordPage;
