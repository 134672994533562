import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const StyledButton = styled.button`
  box-sizing: border-box;
  height: 44px;
  border-radius: 8px;
  outline: none;
  border: none;
  box-shadow: none;
  padding: 9px 16px;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  background: #eaecf0;

  &:hover {
    transform: scale(1.03);
  }

  &:focus {
    box-shadow: 0 0 0 3px;
    /* border: 2px solid; */
    border-radius: 8px;
  }

  ${({ width }) => css`
    width: ${width || "auto"};
  `}

  ${({ minwidth }) => css`
    min-width: ${minwidth || "auto"};
  `}

  ${({ height }) => css`
    height: ${height || "auto"};
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: #eaecf0;
      color: #667085;
      border: none;
      cursor: not-allowed;
    `}

  ${({ theme, disabled, outlined }) =>
    outlined &&
    !disabled &&
    css`
      background-color: transparent;
      color: ${theme.colors["cl-regular-button-color"]};
      border: 1px solid ${theme.colors["cl-regular-button-color"]};
      &:focus {
        box-shadow: 0 0 0 3px ${theme.colors["cl-regular-button-color"]};
      }
    `}

    ${({ theme, primary, disabled }) =>
    primary &&
    !disabled &&
    css`
      color: ${theme.colors["cl-active-button-text"]};
      background-color: ${theme.colors["cl-active-button-bg"]};
      &:focus {
        box-shadow: 0 0 0 3px ${theme.colors["cl-active-button-text"]};
      }
    `}


    ${({ theme, secondary, disabled }) =>
    secondary &&
    !disabled &&
    css`
      color: ${theme.colors["cl-text-dark"]};
      background: #e2e4e7;
      /* background-color: ${theme.colors["cl-active-button-bg"]}; */
      &:focus {
        box-shadow: 0 0 0 3px ${theme.colors["cl-active-button-text"]};
      }
    `}

    ${({ theme, borderless, disabled }) =>
    borderless &&
    !disabled &&
    css`
      color: ${theme.colors["cl-regular-button-color"]};
      background-color: transparent;

      &:hover {
        background-color: #00000010;
      }
    `}
`;

const Button = ({ children, ...otherProps }) => {
  return <StyledButton {...otherProps}>{children}</StyledButton>;
};

Button.propTypes = {
  children: PropTypes.node,
};

export default Button;
