import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import InputPhone from "components/atomic/molecules/InputPhone";

import FieldWrapper from "../FieldWrapper";

const StyledInputPhone = styled(InputPhone)`
  font-size: 0.875rem;

  &:focus {
    border: 1px solid;
    border-radius: 8px;
  }
`;

const PhoneField = ({ column, label, name, control, rules = {}, errors = {}, ...restProps }) => {
  return (
    <FieldWrapper column={column} label={label} name={name} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => <StyledInputPhone {...restProps} {...field} />}
      />
    </FieldWrapper>
  );
};

PhoneField.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
};

export default PhoneField;
