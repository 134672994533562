import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { resetError } from "_store/user/slice";

// import logo from "assets/img/logo.png";
import logo from "assets/img/logos/logo-dark.svg";

import Button from "components/atomic/atoms/Button";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import FieldWrapper from "components/atomic/molecules/fields/FieldWrapper";
import PasswordField from "components/atomic/molecules/fields/PasswordField";
import TextField from "components/atomic/molecules/fields/TextField";
import Card from "components/layouts/Card";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 33.333%;
  min-width: 300px;
  min-height: 200px;
  padding: 15px 25px;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px lightgray;
  margin: auto;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 84px;
  max-height: 84px;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;

  ${({ theme }) => css`
    color: ${theme.colors["cl-main"]};
  `}

  img {
    width: 52px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1120px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
`;
const StyledButton = styled(Button)`
  min-width: 160px;
`;

const LoginForm = ({ defaultValues = {}, onSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const { loading, error } = useSelector(({ user }) => user);

  const rules = useMemo(
    () => ({
      username: () => ({
        required: "Username / E-mail is a required field",
        pattern: {
          value: /^[\S]+$/i,
          message: "Username should not contain whitespaces",
        },
        // maxLength: {
        //   value: 128,
        //   message: "Username should be less or equal 128 symbols",
        // },
        validate: () => {
          if (error) {
            dispatch(resetError());
          }
        },
      }),
      // email: () => ({
      //   required: "Email is a required field",
      //   pattern: {
      //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      //     message: "Invalid Email address",
      //   },
      //   maxLength: {
      //     value: 128,
      //     message: "Email should be less or equal 128 symbols",
      //   },
      //   validate: () => {
      //     if (error) {
      //       dispatch(resetError());
      //     }
      //   },
      // }),
      password: () => ({
        required: "Password is a required field",
        // minLength: {
        //   value: 8,
        //   message: "Password should be more or equal 8 symbols",
        // },
        maxLength: {
          value: 128,
          message: "Password should be less or equal 128 symbols",
        },
        validate: () => {
          if (error) {
            dispatch(resetError());
          }
        },
      }),
    }),
    [error]
  );

  useEffect(() => {
    dispatch(resetError());
  }, []);

  if (loading) {
    return <SpinnerBlock card message="Authorization..." />;
  }

  return (
    <StyledCard>
      <StyledLogo>
        <img src={logo} />
        &nbsp;State&nbsp;Filings
      </StyledLogo>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <TextField
          column
          label="Username / E-mail Address"
          placeholder="Username / E-mail Address"
          name="username"
          control={control}
          errors={errors}
          rules={rules.username()}
        />
        <PasswordField
          column
          className="sessionstack-sensitive"
          label="Password"
          placeholder="Password"
          name="password"
          control={control}
          errors={errors}
          rules={rules.password()}
        />
        <FieldWrapper
          name="authError"
          errors={error ? { authError: { message: "Incorrect Username / E-mail or Password" } } : {}}
        />
        <Buttons>
          <StyledButton
            borderless
            type="button"
            onClick={(e) => {
              e.preventDefault();
              navigate("../forgot-password", { replace: true });
            }}
          >
            Forgot your password?
          </StyledButton>
          <StyledButton primary type="submit">
            Login
          </StyledButton>
        </Buttons>
      </StyledForm>
    </StyledCard>
  );
};

LoginForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default LoginForm;
