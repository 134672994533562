import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { AMENDMENT_PRODUCTS } from "_constants/products";
import { handleError, phoneCleaner, validateTrim } from "_helpers";
import { getStatesOptions } from "_helpers/variables";
import { OrdersService } from "_services";
import { getSubscriptions } from "_store/billing/slice";
import { selectActiveOrderId, selectRAProduct } from "_store/orders/selector";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import FileField from "components/atomic/molecules/fields/FileField";
import PhoneField from "components/atomic/molecules/fields/PhoneField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import SwitchField from "components/atomic/molecules/fields/SwitchField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

    ${({ centerContent }) =>
    centerContent &&
    css`
      justify-content: center;
    `}
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  ${({ smallGap }) =>
    smallGap &&
    css`
      gap: 8px;
    `}

  ${({ $flex }) =>
    $flex &&
    css`
      flex: ${$flex};
    `}
`;

const ColText = styled.div`
  text-align: center;
`;

const Divider = styled.div`
  width: 2px;
  margin: 0 8px;
  background-color: lightgray;
`;

const StyledButton = styled(Button)`
  @media (max-width: 450px) {
    height: 64px;
  }
`;

const ErrorText = styled.div`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;

const ErrorMessage = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 4px gray inset;
  font-family: monospace;
  word-wrap: break-word;
`;

const StyledIcon = styled(Icon)`
  display: inline;
`;

const STEPS = Object.freeze({
  init: "",
  start: "stepStart",
  uploadAmendment: "stepUploadAmendment",
  fileAmendment: "stepFileAmendment",
  loading: "stepLoading",
  error: "stepError",
  fileSuccess: "stepFileSuccess",
  uploadSuccess: "stepUploadSuccess",
});

const rules = {
  firstName: () => ({
    required: "First Name is a required field",
    // maxLength: {
    //   value: 30,
    //   message: "First Name should be less or equal 30 symbols",
    // },
    // pattern: {
    //   value: /^[ a-zA-Z\-&]{1,30}$/i,
    //   message:
    //     "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    // },
    validate: validateTrim,
  }),
  lastName: () => ({
    required: "Last Name is a required field",
    // maxLength: {
    //   value: 30,
    //   message: "Last Name should be less or equal 30 symbols",
    // },
    // pattern: {
    //   value: /^[ a-zA-Z\-&]{1,30}$/i,
    //   message:
    //     "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    // },
    validate: validateTrim,
  }),
  phone: () => ({
    required: "Phone is a required field",
    validate: (value) => {
      if (value?.includes("_")) {
        return "You must provide a valid phone number";
      }

      return true;
    },
  }),
  email: () => ({
    required: "Email is a required field",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid Email address",
    },
    maxLength: {
      value: 100,
      message: "Email should be less or equal 100 symbols",
    },
  }),

  businessName: () => ({
    required: "Business Name is a required field",
    validate: validateTrim,
  }),

  address1: () => ({
    required: "Address is a required field",
    validate: validateTrim,
  }),

  address2: () => ({
    // required: "Apt/Suite is a required field",
    validate: validateTrim,
  }),

  state: () => ({
    required: "State is a required field",
    validate: validateTrim,
  }),

  city: () => ({
    required: "City is a required field",
    // pattern: {
    //   value: /^[ a-zA-Z\-&]{1,30}$/i,
    //   message:
    //     "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    // },
    validate: validateTrim,
  }),

  zip: () => ({
    required: "ZIP is a required field",
    maxLength: {
      value: 5,
      message: "ZIP should be equal 5 digits",
    },
    pattern: {
      value: /^\d{5}$/i,
      message: "Provide ZIP in the required format",
    },
    validate: validateTrim,
  }),

  proofFile: () => ({
    validate: (value) => {
      if (!value) {
        return "Please, provide file";
      }

      if (value?.size && value?.size / 1024 / 1024 >= 3.8) {
        return "File size limit is 3.8MB";
      }

      return true;
    },
  }),
};

// eslint-disable-next-line no-unused-vars
const CancelSubscriptionRAConfirmModal = ({ onCancel, ...otherProps }) => {
  const dispatch = useDispatch();
  const formProofFile = useForm({ defaultValues: { proofFile: null } });
  const formContactInfo = useForm({
    defaultValues: {
      isIndividual: true,
      firstName: "",
      lastName: "",
      businessName: "",
      phone: "",
      email: "",
      address1: "",
      state: "",
      city: "",
      zip: "",
    },
  });

  const valuesContactInfo = useWatch({ control: formContactInfo?.control });

  const orderId = useSelector(selectActiveOrderId);
  const productDetailsRA = useSelector(selectRAProduct);
  const customerId = useSelector(selectCustomerId);

  const [error, setError] = useState(null);
  const [step, setStep] = useState(STEPS.start);

  function handleCloseModal() {
    setError(null);
    setStep(STEPS.init);
    onCancel();
    customerId && dispatch(getSubscriptions({ customerId }));
  }

  async function uploadFile(form) {
    try {
      setStep(STEPS.loading);

      const formData = new FormData();
      formData.append("file", form.proofFile);

      const response = await OrdersService.uploadFile({
        orderId,
        productId: productDetailsRA?.id,
        body: formData,
        fileType: "AMENDMENT_PROOF",
      });

      if (response?.status === 200) {
        setStep(STEPS.uploadSuccess);
      } else {
        setStep(STEPS.error);
        setError({ message: "Issue with file uploading" });
      }
    } catch (error) {
      setStep(STEPS.error);
      setError(handleError(error));
    }
  }

  async function createAmendmentUpsale(form) {
    try {
      setStep(STEPS.loading);

      const body = {
        order: { processingOption: "UPSELL", campaignId: 28 },
        products: [
          {
            code: { code: AMENDMENT_PRODUCTS.incStatesAmendmentRegisteredAgent },
            businessName: !form?.isIndividual && form?.businessName ? form?.businessName : null,
            individual: {
              firstName: form?.firstName ? form?.firstName : null,
              lastName: form?.isIndividual && form?.lastName ? form?.lastName : null,
              email: form?.email || null,
              phone: phoneCleaner(form?.phone) || null,
              address: {
                address1: form?.address1 || null,
                state: form?.state || null,
                city: form?.city || null,
                zip: form?.zip || null,
                country: "US",
              },
            },
          },
        ],
      };

      const request = await OrdersService.createDelayedUpsell({ orderId, body });

      if (request?.status === 200) {
        if (request?.data?.products[0]?.paymentStatus === "Declined") {
          setError({
            message: "Uh oh - your card on file has been declined. Please update your payment method to proceed.",
          });
          setStep(STEPS.error);
        } else {
          setStep(STEPS.fileSuccess);
        }
      } else {
        setStep(STEPS.error);
        setError({ message: "Unknown Error" });
      }
    } catch (response) {
      setStep(STEPS.error);
      setError(handleError(response));
    }
  }

  useEffect(() => {
    orderId && setStep(STEPS.start);
  }, [orderId]);

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered show onHide={handleCloseModal} size="lg">
          <StyledHeader closeButton>Cancel Subscription</StyledHeader>
          <StyledBody>
            <Row>
              In order to cancel this service, an amendment must be filed with the state to replace the registered agent
              contact information on your filing.
            </Row>
            <Row bold centerContent>
              Please, select one of options:
            </Row>
            <Row>
              <Col>
                <ColText>
                  If you have already filed the amendment, please select this option and upload the required proof that
                  you have filed with the state.
                </ColText>
                <StyledButton
                  variant="secondary"
                  onClick={() => {
                    setStep(STEPS.uploadAmendment);
                  }}
                >
                  Upload Amendment
                </StyledButton>
              </Col>
              <Divider />
              <Col>
                <ColText>
                  If you need us to file the amendment for you for <b>$99</b>, select this option.
                </ColText>
                <StyledButton
                  variant="success"
                  onClick={() => {
                    setStep(STEPS.fileAmendment);
                  }}
                >
                  File My Amendment
                </StyledButton>
              </Col>
            </Row>
          </StyledBody>
        </Modal>
      );

    case STEPS.uploadAmendment:
      return (
        <Modal centered show onHide={handleCloseModal} size="md">
          <form onSubmit={formProofFile.handleSubmit(uploadFile)}>
            <StyledHeader closeButton>Cancel Subscription</StyledHeader>
            <StyledBody>
              <Row>Please upload your Registered Agent Amendment Filing Confirmation</Row>
              <Row>
                <FileField
                  column
                  // label="File"
                  name="proofFile"
                  control={formProofFile.control}
                  errors={formProofFile.formState.errors}
                  rules={rules.proofFile()}
                />
              </Row>
            </StyledBody>
            <Modal.Footer>
              <Button variant="success" type="submit">
                Upload
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      );

    case STEPS.fileAmendment:
      return (
        <Modal centered show onHide={handleCloseModal} size="md" backdrop="static">
          <form onSubmit={formContactInfo.handleSubmit(createAmendmentUpsale)}>
            <StyledHeader closeButton>Cancel Subscription</StyledHeader>
            <StyledBody>
              <Row>Please provide the contact information of the new Registered Agent for the amendment:</Row>
              <Row>
                <SwitchField
                  column
                  name="isIndividual"
                  labelOn="First Name & Last Name"
                  labelOff="Business Name"
                  control={formContactInfo.control}
                  errors={formContactInfo.formState.errors}
                />
              </Row>
              {valuesContactInfo?.isIndividual && (
                <Row>
                  <Col>
                    <TextField
                      column
                      name="firstName"
                      placeholder="First Name"
                      control={formContactInfo.control}
                      errors={formContactInfo.formState.errors}
                      rules={rules.firstName()}
                    />
                  </Col>
                  <Col>
                    <TextField
                      column
                      name="lastName"
                      placeholder="Last Name"
                      control={formContactInfo.control}
                      errors={formContactInfo.formState.errors}
                      rules={rules.lastName()}
                    />
                  </Col>
                </Row>
              )}
              {!valuesContactInfo?.isIndividual && (
                <Row>
                  <Col>
                    <TextField
                      column
                      name="businessName"
                      placeholder="Business Name"
                      control={formContactInfo.control}
                      errors={formContactInfo.formState.errors}
                      rules={rules.businessName()}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col $flex="7">
                  <TextField
                    column
                    name="address1"
                    label="Address"
                    placeholder="Address"
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.address1()}
                  />
                </Col>
                <Col $flex="5">
                  <TextField
                    column
                    name="address2"
                    label="Apt or Suite"
                    placeholder="Apt or Suite"
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.address2()}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectField
                    column
                    name="state"
                    label="State"
                    placeholder="State"
                    options={getStatesOptions(true)}
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.state()}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    column
                    name="city"
                    label="City"
                    placeholder="City"
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.city()}
                  />
                </Col>
                <Col>
                  <TextField
                    column
                    name="zip"
                    label="ZIP"
                    placeholder="12345"
                    maxLength={5}
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.zip()}
                  />
                </Col>

                <Row>
                  <Col>
                    <PhoneField
                      column
                      label="Phone"
                      placeholder="Phone"
                      name="phone"
                      control={formContactInfo.control}
                      errors={formContactInfo.formState.errors}
                      rules={rules.phone()}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <TextField
                      column
                      autocomplete="email"
                      label="Email"
                      placeholder="Email"
                      name="email"
                      control={formContactInfo.control}
                      errors={formContactInfo.formState.errors}
                      rules={rules.email()}
                    />
                  </Col>
                </Row>
              </Row>
            </StyledBody>
            <Modal.Footer>
              <Button variant="success" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      );

    case STEPS.fileSuccess:
      return (
        <Modal centered show onHide={handleCloseModal} size="md">
          <StyledHeader>Cancel Subscription</StyledHeader>
          <StyledBody>
            <Row>Thank you! Your Registered Agent Amendment order has successfully been placed.</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.uploadSuccess:
      return (
        <Modal centered show onHide={handleCloseModal} size="md">
          <StyledHeader>Cancel Subscription</StyledHeader>
          <StyledBody>
            <Row>
              Thank you for your file! Our specialists will review to make sure that the amendment was successfully
              filed and cancel your Registered Agent Subscription.
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show backdrop="static" onHide={() => {}} size="md">
          <StyledHeader>Cancel Subscription</StyledHeader>
          <StyledBody>
            <SpinnerBlock message="Sending Request" />
          </StyledBody>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show onHide={handleCloseModal} size="md">
          <StyledHeader closeButton>Cancel Subscription</StyledHeader>
          <StyledBody>
            <Row>
              <Col smallGap>
                <ErrorText>
                  <StyledIcon icon="error:alert" size="24px" />
                  &nbsp;Error:
                </ErrorText>
                <ErrorMessage>{error?.message || "Something went wrong..."}</ErrorMessage>
              </Col>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

CancelSubscriptionRAConfirmModal.propTypes = {
  onCancel: PropTypes.func,
};

export default CancelSubscriptionRAConfirmModal;
