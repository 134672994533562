import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(8px);
`;
const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 48px 32px;
  margin: 0 12px;
  min-width: 33.333%;

  ${({ theme }) => css`
    border: 3px solid ${theme.colors["cl-secondary"]};
  `}

  animation: fadeIn 0.5s;
  ${({ theme }) => theme.animations.fadeIn}
`;
const CardContent = styled.div`
  width: 100%;
  height: 100%;
`;
const StyledCardHeader = styled.div`
  margin-bottom: 12px;
  font-size: 1.125rem;
  text-align: center;
`;
const HeaderRow = styled.div``;

const DefaultOverlay = () => {
  return (
    <Container>
      <StyledCard>
        <CardContent>
          <StyledCardHeader>
            <HeaderRow>{`Your business does not have this functionality at the moment.`}</HeaderRow>
          </StyledCardHeader>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default DefaultOverlay;
