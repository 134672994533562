import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { PRODUCT_CODES } from "_constants/products";
import { selectIsRequiredProductsChecked, selectRequiredProducts } from "_store/application/selector";

import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import BusinessLicenseOverlay from "components/atomic/molecules/overlays/BusinessLicenseOverlay";
import DefaultOverlay from "components/atomic/molecules/overlays/DefaultOverlay";

// import TrademarkUpsellOverlay from "components/atomic/molecules/overlays/TrademarkUpsellOverlay";

const getOverlay = (key, requiredProducts) => {
  const { hasBusinessLicenses } = requiredProducts;

  switch (key) {
    // case PRODUCT_CODES.incStatesRegisterTrademark:
    //   return !hasTrademarkRegister ? <TrademarkUpsellOverlay /> : <Outlet />;

    case PRODUCT_CODES.incStatesBusinessLicenses:
      return !hasBusinessLicenses ? <BusinessLicenseOverlay /> : <Outlet />;

    default:
      return <DefaultOverlay />;
  }
};

const RequireProduct = ({ code }) => {
  const requiredProducts = useSelector(selectRequiredProducts);

  const isRequiredProductsChecked = useSelector(selectIsRequiredProductsChecked);

  if (!isRequiredProductsChecked) {
    return <SpinnerBlock />;
  }

  return getOverlay(code, requiredProducts);
};

RequireProduct.propTypes = {
  code: PropTypes.string,
};

export default RequireProduct;
