import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  a:hover {
    color: inherit;
  }

  // 208°, 54%, 71% #5387b5
  // 207°, 54%, 56% #426c8f
  // 208°, 54%, 52% #3d6385
  // 206°, 54%, 48% #385d7a
  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #5387b5;
    --bs-btn-border-color: #5387b5;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #426c8f;
    --bs-btn-hover-border-color: #3d6385;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3d6385;
    --bs-btn-active-border-color: #385d7a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5387b5;
    --bs-btn-disabled-border-color: #5387b5;
  }

  // 0°, 69%, 90% #e64747
  // 0°, 69%, 75% #bf3b3b
  // 0°, 69%, 71% #b53838
  // 0°, 69%, 67% #ab3535
  .btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #e64747;
    --bs-btn-border-color: #e64747;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bf3b3b;
    --bs-btn-hover-border-color: #b53838;
    --bs-btn-focus-shadow-rgb: 225,83,97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b53838;
    --bs-btn-active-border-color: #ab3535;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #e64747;
    --bs-btn-disabled-border-color: #e64747;
  }

  .animated-placeholder {
    position: relative;
    overflow: hidden;
    z-index: 1;
    
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-image: linear-gradient(90deg, rgba(255,255,255,0) 46%, #fff 52%, rgba(255,255,255,0) 58%);
      background-size: 400% 100%;

      @keyframes skeleton-loading {
        from {
          background-position: 100% 50%;
        }
        to {
          background-position: 0 50%;
        }
      }

      animation: skeleton-loading 1.4s ease infinite;
    }
  }
`;
