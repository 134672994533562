import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";

import { validateTrim } from "_helpers";

import AreaField from "components/atomic/molecules/fields/AreaField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

// const FieldsGroupHeader = styled.div`
//   text-align: center;
//   margin: 0 0 24px 0;
// `;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > * {
    flex: 1;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

// const ButtonsGroup = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   gap: 24px;
// `;

const rules = {
  description: () => ({
    required: "Description is a required field",
    validate: validateTrim,
  }),
};

const initValues = {
  description: "",
};

// eslint-disable-next-line react/display-name
const AmendmentOtherForm = forwardRef((props, ref) => {
  const { handlers } = props;
  const { onSubmit } = handlers;

  const form = useForm({ defaultValues: initValues, reValidateMode: "onChange" });
  const {
    control,
    formState: { errors },
    handleSubmit: createSubmitHandler,
  } = form;

  const handleSubmit = createSubmitHandler(onSubmit);

  useImperativeHandle(
    ref,
    () => ({
      submit: () => handleSubmit(),
    }),
    [handleSubmit]
  );

  return (
    <>
      <StyledForm ref={ref} onSubmit={handleSubmit}>
        <FieldsGroup>
          <p>
            Please provide what you would like to amend, and the new information that will be replacing the existing.
          </p>
          <FieldsGroupContent>
            <AreaField
              column
              name="description"
              // label="Description"
              placeholder="Describe your amendment..."
              control={control}
              errors={errors}
              rules={rules.description()}
            />
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>
    </>
  );
});

export default AmendmentOtherForm;

AmendmentOtherForm.propTypes = {
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
};
