import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

import { PAYMENT_STATUSES, RECENT_BILLS_STATUSES } from "_constants/billing";
import { currencyFormatter, handleProductName } from "_helpers";
import useIsDesktopSize from "_hooks/useIsDesktopSize";

import Indicator from "components/atomic/atoms/Indicator";
import Table from "components/atomic/molecules/Table";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  overflow: hidden;
  padding: 0 12px;
`;

const Date = styled.div`
  font-style: italic;
  font-size: 0.87rem;
`;

const OrderId = styled.div`
  font-size: 0.87rem;
`;

const Price = styled.div`
  font-weight: 500;
`;

const ProductItem = styled.div`
  font-size: 0.825rem;
  max-width: max-content;
  padding: 4px 8px;
  border-radius: 12px;
  margin-bottom: 8px;
  word-break: break-word;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-main"] + "28"};
    color: ${theme.colors["cl-text-black"]};
  `}
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const getRecentBillsColumns = (isDesktop) => {
  return [
    {
      dataIndex: "paymentStatusValue",
      title: "Status",
      width: "35%",
      minWidth: "78px",
      render: (row) => (
        <div>
          <StatusContainer>
            <Indicator status={row?.paymentStatus === PAYMENT_STATUSES.Success} />
            &nbsp;{RECENT_BILLS_STATUSES[row?.paymentStatus] || row?.paymentStatus}
          </StatusContainer>
          {row?.paymentDate && !row?.paymentDate?.includes("null") && (
            <Date>
              {isDesktop
                ? moment(row?.paymentDate).format("MMMM DD, YYYY")
                : moment(row?.paymentDate).format("MMM DD, YYYY")}
            </Date>
          )}
          <OrderId>ID: {row?.crmOrderId}</OrderId>
        </div>
      ),
    },
    {
      dataIndex: "amount",
      title: "Total",
      width: "25%",
      minWidth: "72px",
      render: (row) => {
        return <Price>{currencyFormatter(row?.amount)}</Price>;
      },
    },
    {
      dataIndex: "productCodes",
      title: "Products",
      width: "40%",
      minWidth: "140px",
      render: (row) => {
        return (
          <div>
            {row?.productCodes?.map((item, idx) => (
              <ProductItem key={`productCode-${item?.code || "unknown"}-${idx}`}>
                {handleProductName(item?.name) || "N/A"}
              </ProductItem>
            ))}
          </div>
        );
      },
    },
  ];
};

const RecentBillsListModal = ({ bills, onCancel }) => {
  const isDesktop = useIsDesktopSize();

  const columns = getRecentBillsColumns(isDesktop);

  return (
    <Modal centered show onHide={onCancel} size="md">
      <StyledHeader closeButton>Recent Bills List</StyledHeader>
      <StyledBody>
        <Table columns={columns} rows={bills} skeletonRowsNum={3} />
      </StyledBody>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RecentBillsListModal.propTypes = {
  bills: PropTypes.array,
  onCancel: PropTypes.func,
};

export default RecentBillsListModal;
