import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { config } from "_config";
import { handleProductName } from "_helpers";
import {
  selectActionRequiredProducts,
  selectActionRequiredProductsCount,
  selectActiveOrderNotes,
  selectCustomActionRequired,
} from "_store/orders/selector";

import CustomLink from "components/atomic/atoms/CustomLink";
import EmptyMessage from "components/atomic/atoms/EmptyMessage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-height: 100%;
  height: 100%;
  /* padding: 0 12px; */
  animation: fadeIn 0.5s;
  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;
  overflow-y: auto;

  ${({ theme }) => theme.animations.fadeIn}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 12px;
  padding: 4px 12px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  width: 100%;
  background-color: white;
  &:nth-child(2) {
    margin-top: 12px;
  }
`;

const ItemContent = styled.div`
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const Date = styled.div`
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;

const BlockHeader = styled.div`
  position: sticky;
  top: 0;
  padding: 10px 12px;
  font-size: 1.1rem;
  background-color: #fff;
  /* box-shadow: 0px 2px 3px 2px lightgray; */
  box-shadow: 0px 2px 4px 1px lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-top: 1px solid lightgray;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;
const BlockBody = styled.div`
  padding: 0 12px;
`;

const NotificationsPage = () => {
  const notes = useSelector(selectActiveOrderNotes);
  const actionRequiredProducts = useSelector(selectActionRequiredProducts);
  const customActions = useSelector(selectCustomActionRequired);
  const actionsCount = useSelector(selectActionRequiredProductsCount);

  const unresolvedActions = actionRequiredProducts?.unresolved;

  return (
    <Container>
      {notes?.length ? (
        <>
          <BlockHeader>{`Action Required: ${actionsCount || "0"}`}</BlockHeader>
          <BlockBody>
            {customActions.map((action, idx) => (
              <Item key={`${idx}-custom-action-item`}>
                <Date>{action?.notificationTitle}</Date>
                <ItemContent>
                  <CustomLink
                    to={action?.redirectUrl || `${config.publicUrl}/dashboard/action-details/-/${action?.type}`}
                  >
                    {action?.notificationDescription || "Action required"}
                  </CustomLink>
                </ItemContent>
              </Item>
            ))}

            {unresolvedActions.map((action, idx) => (
              <Item key={`${idx}-action-item`}>
                <Date>
                  {handleProductName(action?.product?.customerName) ||
                    handleProductName(action?.product?.code?.code) ||
                    "Unknown Product"}
                </Date>
                <ItemContent>
                  <CustomLink to={`${config.publicUrl}/dashboard/action-details/${action?.product?.id}/ACTION`}>
                    {action?.actionRequired?.actionRequired || "Action required"}
                  </CustomLink>
                </ItemContent>
              </Item>
            ))}
          </BlockBody>

          <BlockHeader>Notifications: {notes.length}</BlockHeader>
          <BlockBody>
            {notes.map((note, idx) => (
              <Item key={`${idx}-${note.date}`}>
                <Date>{moment(note.date).format("MM/DD/YY - HH:mm:ss")}</Date>
                <ItemContent>{handleProductName(note.message)}</ItemContent>
              </Item>
            ))}
          </BlockBody>
        </>
      ) : (
        <EmptyMessage full>There are no notifications here yet</EmptyMessage>
      )}
    </Container>
  );
};

export default NotificationsPage;
