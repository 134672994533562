import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { components } from "react-select";
import SelectReact from "react-select";
import styled, { css } from "styled-components";

import useIsDesktopSize from "_hooks/useIsDesktopSize";

import MobileListModal from "components/atomic/atoms/Select/MobileListModal";

import { ReactComponent as TriangleIcon } from "./assets/dropdown.svg";

const SelectStyled = styled(SelectReact)`
  text-align: left;
  width: 100%;
  height: 44px;
  font-size: 0.875rem;
  font-weight: 400;

  ${({ minwidth }) =>
    minwidth &&
    css`
      min-width: ${minwidth};
    `}
  ${({ maxwidth }) =>
    maxwidth &&
    css`
      max-width: ${maxwidth};
    `}

  ${({ theme, secondary }) => css`
    .react-select__control {
      cursor: pointer;
      height: 100%;
      border-radius: 8px;
      outline: none;
      border-color: ${secondary ? "#EAECF0" : "transparent"};
      background-color: ${secondary ? "#FFFFFF" : theme.colors["cl-select-bg"]};
      transition: border-color 0.1s;
      box-shadow: none;
      &:hover {
        border: 1px solid ${theme.colors["cl-select-focus"]};
        /* border-color: ${secondary ? "#bdc6da" : "transparent"}; */
      }
      &--is-focused,
      &:focus {
        border: 1px solid ${theme.colors["cl-select-focus"]};
      }
    }
    .react-select__single-value {
      color: ${theme.colors["cl-text-light"]};
    }
    .react-select__menu {
      background-color: #f9fafb;
    }
    .react-select__option {
      cursor: pointer;
      background-color: ${theme.colors["cl-select-bg"]};
      color: ${theme.colors["cl-text-light"]};
      &:hover {
        background-color: ${theme.colors["cl-select-hover"]};
      }
    }
    .react-select__placeholder {
      color: ${theme.colors["cl-text-light"]};
    }
  `}
`;

const DropdownIndicator = (innerProps) => {
  return (
    <components.DropdownIndicator {...innerProps}>
      <TriangleIcon />
    </components.DropdownIndicator>
  );
};

const Input = (innerProps) => {
  const { autoComplete = innerProps.autoComplete } = innerProps.selectProps;
  return (
    <components.Input
      {...innerProps}
      autoComplete={autoComplete || "off"}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={{ display: "none" }} {...innerProps} />;
};

// eslint-disable-next-line react/display-name
const Select = forwardRef(({ disabled, secondary, options, value = "", onChange, ...otherProps }, ref) => {
  const isDesktop = useIsDesktopSize();

  if (!isDesktop) {
    return (
      <SelectStyled
        ref={ref}
        data-testid="my-select-component"
        classNamePrefix="react-select"
        components={{
          Menu: MobileListModal,
          DropdownIndicator,
          IndicatorSeparator,
          Input,
        }}
        openMenuOnFocus={false}
        openMenuOnClick={true}
        menuPosition="fixed"
        options={options}
        value={value}
        onChange={onChange}
        secondary={secondary}
        isDisabled={disabled}
        {...otherProps}
      />
    );
  }

  return (
    <SelectStyled
      ref={ref}
      data-testid="my-select-component"
      classNamePrefix="react-select"
      components={{ DropdownIndicator, IndicatorSeparator, Input }}
      options={options}
      value={value}
      onChange={onChange}
      secondary={secondary}
      isDisabled={disabled}
      {...otherProps}
    />
  );
});

IndicatorSeparator.propTypes = {
  innerProps: PropTypes.object,
};

Select.propTypes = {
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default Select;
