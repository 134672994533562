import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Icon from "components/atomic/atoms/Icon";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  z-index: 4000;
  overflow-y: auto;
  background-color: white;
`;
const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100px;
  max-height: 100px;
  width: 100%;
  box-shadow: 0px -2px 8px lightgray inset;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  color: #555555;
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  color: #555555;
  font-weight: bold;
  font-size: 1.5rem;
  cursor: pointer;
`;
const List = styled.div`
  min-height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  width: 100%;
  overflow-y: auto;

  animation: 0.7s fadeIn;

  .react-select__menu-list {
    max-height: max-content;
    padding: 8px;
  }

  .react-select__option {
    cursor: pointer;
    font-size: 1.3rem;
    border: 1px solid lightgray;
    border-radius: 12px;
    padding: 8px 48px;
    margin-bottom: 12px;

    @media (max-width: 575px) {
      padding: 8px 12px;
    }
  }
`;

const MobileListModal = (props) => {
  const { children, innerRef, innerProps } = props;

  const [showList, setShowList] = useState();

  const isSearchable = props?.selectProps?.isSearchable;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowList(true);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Container
      {...innerProps}
      ref={innerRef}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
    >
      <TitleContainer>
        <Title>Please, select option:</Title>
        {isSearchable && (
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={props?.selectProps?.inputValue || ""}
              onChange={(e) => props?.selectProps?.onInputChange(e.target.value)}
            />
          </div>
        )}
        <CloseButton
          onClick={(e) => {
            e.stopPropagation();
            props?.selectProps?.onMenuClose();
          }}
        >
          <Icon icon="sidebar:close" size="32px" />
        </CloseButton>
      </TitleContainer>

      <List>{showList ? children : null}</List>
    </Container>
  );
};

MobileListModal.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.object,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

export default MobileListModal;
