import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { selectRAProduct } from "_store/orders/selector";

import colors from "components/Theme/colors";
import Icon from "components/atomic/atoms/Icon";
import BlockedRAModal from "components/atomic/organisms/modals/BlockedRAModal";

const Container = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  box-sizing: border-box;
  max-height: 100%;
  height: 100%;
  width: 100%;

  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;

  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  /* gap: 30px; */
  width: 100%;
  min-height: 100%;
  /* max-width: 700px; */

  @media (max-width: 991px) {
    align-items: start;
  }
`;

const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 24px 32px;
  margin: 0 12px;
  min-width: 33.333%;
  max-width: 1080px;

  ${({ theme }) => css`
    border: 3px solid ${theme.colors["cl-indicator-inactive"]};
  `}

  ${({ $processing, theme }) =>
    $processing &&
    css`
      border: 3px solid ${theme.colors["cl-indicator-processing"]};
    `}

  animation: fadeIn 0.5s;
  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 760px) {
    padding: 18px 12px 48px 12px;
  }
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
`;
const StyledCardHeader = styled.div`
  width: 100%;
  margin-bottom: 12px;
  /* text-align: center; */

  h2 {
    margin: 0;
    font-size: 1.2rem;
    text-align: left;
  }
`;

const StyledRow = styled.div`
  ${({ $centered }) =>
    $centered &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}
`;

const Columns = styled.div`
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: row;
  /* gap: 12px; */
  height: max-content;

  border-radius: 4px;
  padding: 8px 0;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 4px;

  ${({ smallGap }) =>
    smallGap &&
    css`
      gap: 8px;
    `}
`;

const ColText = styled.div`
  text-align: center;
`;

const Divider = styled.div`
  width: 2px;
  margin: 0 8px;
  background-color: lightgray;

  @media (max-width: 760px) {
    height: 2px;
    width: 100%;
    margin: 8px 0;
  }
`;

const StyledButton = styled(Button)`
  width: 90%;
  height: 56px;
`;

const actions = Object.freeze({
  submitPayment: "submitPayment",
  fileAmendment: "fileAmendment",
  uploadAmendment: "uploadAmendment",
});

const RegisteredAgentBlockedPage = () => {
  const [showBlockModal, setShowBlockModal] = useState("");

  const productDetailsRA = useSelector(selectRAProduct);
  const hasAmendmentProofUploaded = Object.values(productDetailsRA?.processingResult?.files || {}).some(
    (file) => file?.type === "AMENDMENT_PROOF"
  );
  const isAgentReviewStatus = productDetailsRA?.processingResult?.status === "AgentReview";

  return (
    <>
      <Container>
        <ContentRow>
          {/* <RegisteredAgentBlockedOverlay /> */}

          {hasAmendmentProofUploaded && isAgentReviewStatus ? (
            <StyledCard $processing>
              <StyledCardHeader>
                <h2>
                  <Icon inline icon="misc:timer" size="48px" color={colors["cl-indicator-processing"]} />
                  &nbsp;Uploaded amendment in processing of Agent Review
                </h2>
              </StyledCardHeader>
              <CardContent>
                <StyledRow>
                  Our specialists will review to make sure that the amendment was successfully filed and remove the
                  block from your Membership as soon as possible. After that, you should reload the page to get full
                  access.
                </StyledRow>
                <StyledRow $centered>
                  <Button variant="primary" size="lg" onClick={() => window.location.reload()}>
                    Reload Page
                  </Button>
                </StyledRow>
              </CardContent>
            </StyledCard>
          ) : (
            <StyledCard>
              <StyledCardHeader>
                <h2>Registered Agent Invoice Due</h2>
              </StyledCardHeader>
              <CardContent>
                <StyledRow>
                  Our records show that your account has an outstanding balance of <b>$199.00</b> for your Registered
                  Agent. To restore access to your account, documents, and other services, you will need to move forward
                  with one of the following options:
                </StyledRow>

                <Columns>
                  <Col>
                    <ColText>Pay the outstanding balance to reinstate your Registered Agent service</ColText>
                    <StyledButton
                      variant="primary"
                      onClick={() => {
                        setShowBlockModal(actions.submitPayment);
                      }}
                    >
                      Submit Registered Agent Payment
                    </StyledButton>
                  </Col>
                  <Divider />
                  <Col>
                    <ColText>Pay to file an amendment and designate a new Registered Agent</ColText>
                    <StyledButton
                      variant="primary"
                      onClick={() => {
                        setShowBlockModal(actions.fileAmendment);
                      }}
                    >
                      File an Amendment
                    </StyledButton>
                  </Col>
                  <Divider />
                  <Col>
                    <ColText>File the amendment yourself and upload proof of filing</ColText>
                    <StyledButton
                      variant="primary"
                      onClick={() => {
                        setShowBlockModal(actions.uploadAmendment);
                      }}
                    >
                      Upload Amendment Proof
                    </StyledButton>
                  </Col>
                </Columns>

                <StyledRow>
                  Your account access will be immediately restored upon successful payment or when the amendment is
                  filed.
                </StyledRow>
              </CardContent>
            </StyledCard>
          )}
        </ContentRow>
      </Container>
      {showBlockModal && (
        <BlockedRAModal action={showBlockModal} actions={actions} onCancel={() => setShowBlockModal("")} />
      )}
    </>
  );
};

export default RegisteredAgentBlockedPage;
