import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { validateTrim } from "_helpers";
import { selectCompanyName } from "_store/orders/selector";

import TextField from "components/atomic/molecules/fields/TextField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

// const FieldsGroupHeader = styled.div`
//   text-align: center;
//   margin: 0 0 24px 0;
// `;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > * {
    flex: 1;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

// const ButtonsGroup = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   gap: 24px;
// `;

const rules = {
  newCompanyName: (oldCompanyName) => ({
    required: "New Business Name is a required field",
    validate: (value) => {
      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      if (oldCompanyName === value) {
        return "Please, provide a name other than the previous one";
      }

      return true;
    },
  }),
};

const initValues = {
  newCompanyName: "",
};

// eslint-disable-next-line react/display-name
const AmendmentBusinessNameForm = forwardRef((props, ref) => {
  const { handlers } = props;
  const { onSubmit } = handlers;

  const companyName = useSelector(selectCompanyName);

  const form = useForm({ defaultValues: initValues, reValidateMode: "onChange" });
  const {
    control,
    formState: { errors },
    handleSubmit: createSubmitHandler,
  } = form;

  const handleSubmit = createSubmitHandler(onSubmit);

  useImperativeHandle(
    ref,
    () => ({
      submit: () => handleSubmit(),
    }),
    [handleSubmit]
  );

  return (
    <>
      <StyledForm ref={ref} onSubmit={handleSubmit}>
        <FieldsGroup>
          <p>New Business Name</p>
          <FieldsGroupContent>
            <TextField
              column
              name="newCompanyName"
              // label="New Business Name"
              placeholder="Business Name..."
              control={control}
              errors={errors}
              rules={rules.newCompanyName(companyName)}
            />
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>
    </>
  );
});

export default AmendmentBusinessNameForm;

AmendmentBusinessNameForm.propTypes = {
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
};
