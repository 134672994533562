import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { MAIN_CATEGORY } from "_constants/products";
import { selectActiveOrderDetails, selectActiveOrderId } from "_store/orders/selector";
import { contactUs } from "_store/orders/slice";
import { selectContactData } from "_store/user/selector";

// import Indicator from "components/atomic/atoms/Indicator";
// import Card from "components/layouts/Card";
// import CardContent from "components/layouts/CardContent";
// import CardHeader from "components/layouts/CardHeader";
import SupportForm from "../organisms/forms/SupportForm";

const Container = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  box-sizing: border-box;
  max-height: 100%;
  height: 100%;
  width: 100%;

  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;

  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentRow = styled.div`
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  /* gap: 30px; */
  width: 100%;
  max-width: 700px;
`;

// const StyledCard = styled(Card)`
//   width: 100%;
//   /* padding: 12px; */
// `;

// const StyledCardHeader = styled(CardHeader)`
//   justify-content: space-between;

//   @media (max-width: 450px) {
//     padding: 12px;
//   }
// `;

// const StyledCardContent = styled(CardContent)`
//   overflow-y: unset;
//   padding: 12px 30px;

//   @media (max-width: 450px) {
//     padding: 12px;
//   }
// `;

// const TicketItem = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 20px;
//   width: 100%;
//   min-height: 40px;
//   font-weight: 400;
//   color: ${({ theme }) => theme.colors["cl-text-dark"]};
// `;

// const Number = styled.div`
//   flex-grow: 0;
// `;
// const Description = styled.div`
//   flex-grow: 1;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// `;

const SupportPage = () => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectActiveOrderId);
  const orderDetails = useSelector(selectActiveOrderDetails);
  const customerData = useSelector(selectContactData);

  return (
    <Container>
      <ContentRow>
        <SupportForm
          onSubmit={(values) => {
            dispatch(
              contactUs({
                domain: "state-filings.com",
                orderId: orderDetails?.crmOrderId || orderId,
                category: MAIN_CATEGORY,
                ...customerData,
                ...values,
              })
            );
          }}
        />
      </ContentRow>
      {/* <ContentRow>
        <StyledCard height="231px">
          <StyledCardHeader>Open Tickets</StyledCardHeader>
          <StyledCardContent>
            <div style={{ overflowY: "auto" }}>
              <TicketItem>
                <Indicator status={true} />
                <Number>#426486</Number>
                <Description>Ticket 1</Description>
              </TicketItem>
              <TicketItem>
                <Indicator status={true} />
                <Number>#596970</Number>
                <Description>Ticket 2</Description>
              </TicketItem>
              <TicketItem>
                <Indicator status={false} />
                <Number>#454757</Number>
                <Description>Ticket 3</Description>
              </TicketItem>
              <TicketItem>
                <Indicator status={false} />
                <Number>#454757</Number>
                <Description>
                  Deserunt ea occaecat magna sit enim irure velit ad magna ullamco mollit occaecat reprehenderit non.
                </Description>
              </TicketItem>
            </div>
          </StyledCardContent>
        </StyledCard>
      </ContentRow> */}
    </Container>
  );
};

export default SupportPage;
