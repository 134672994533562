import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Popover from "react-bootstrap/Popover";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { config } from "_config";
import { handleProductName } from "_helpers";
import {
  selectActionRequiredProducts,
  selectActionRequiredProductsCount,
  selectActiveOrderNotes,
  selectCustomActionRequired,
} from "_store/orders/selector";

import CustomLink from "components/atomic/atoms/CustomLink";
import EmptyMessage from "components/atomic/atoms/EmptyMessage";

const Container = styled(Popover)`
  display: flex;
  flex-direction: column;

  min-width: 320px;
  height: 480px;
  box-shadow: 0 0 15px lightgray;

  animation: 0.3s fadeIn;

  .popover-arrow {
    position: absolute;
    left: calc(50% - 8px);
    top: -8px;

    &::before {
      border-bottom-color: rgba(0, 0, 0, 0.175);
    }
    &::after {
      border-bottom-color: #f0f0f0;
    }
  }
`;

const Header = styled(Popover.Header)`
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 40px;

  &:not(:nth-child(2)) {
    border-radius: unset;
  }

  & a {
    font-size: 0.875rem;
    cursor: pointer;
    color: #7c8db5 !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
`;

const Body = styled(Popover.Body)`
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;
const ActionsBody = styled(Body)`
  min-height: max-content;
  max-height: 150px;
`;
const NotesBody = styled(Body)`
  height: 200px;
  flex-grow: 1;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.175);

  & a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Item = styled.div`
  padding: 4px 12px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.175); */
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1) inset;
  width: 100%;
  min-height: 80px;
`;

const ItemSm = styled(Item)`
  min-height: 50px;
`;

const ItemContent = styled.div`
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const Date = styled.div`
  /* text-align: right; */
  font-size: 12px;
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;

const NotificationsPopup = (props) => {
  const { show, hideHandler } = props;

  const notes = useSelector(selectActiveOrderNotes);
  const actionRequiredProducts = useSelector(selectActionRequiredProducts);
  const customActions = useSelector(selectCustomActionRequired);
  const actionsCount = useSelector(selectActionRequiredProductsCount);

  const unresolvedActions = actionRequiredProducts?.unresolved;

  const getNotesLengthString = () => {
    if (!notes?.length) return "0/0";
    if (notes?.length < 11) return `${notes?.length}/${notes?.length}`;
    return `10/${notes?.length}`;
  };

  if (!show) return null;

  return (
    <Container placement="bottom">
      <Header>{`Action Required (${actionsCount || 0})`}</Header>
      <ActionsBody>
        {customActions?.map((action, idx) => (
          <ItemSm key={`${idx}-custom-title-action`}>
            <Date>{action?.notificationTitle}</Date>
            <ItemContent>
              <CustomLink
                to={action?.redirectUrl || `${config.publicUrl}/dashboard/action-details/-/${action?.type}`}
                onClick={() => {
                  hideHandler();
                }}
              >
                {action?.notificationDescription || "Action required"}
              </CustomLink>
            </ItemContent>
          </ItemSm>
        ))}

        {unresolvedActions?.map((action, idx) => (
          <ItemSm key={`${idx}-title-action`}>
            <Date>{action?.product?.customerName || action?.product?.code?.code || "Unknown Product"}</Date>
            <ItemContent>
              <CustomLink
                to={`${config.publicUrl}/dashboard/action-details/${action?.product?.id}/ACTION`}
                onClick={() => {
                  hideHandler();
                }}
              >
                {action?.actionRequired?.actionRequired || "Action required"}
              </CustomLink>
            </ItemContent>
          </ItemSm>
        ))}
      </ActionsBody>
      <Header>
        Notifications ({getNotesLengthString()}){/* <a>Mark as Read</a> */}
      </Header>
      <NotesBody>
        {notes?.length ? (
          notes.slice(0, 10).map((note, idx) => (
            <Item key={`${idx}-${note.date}-note`}>
              <Date>{moment(note.date).format("MM/DD/YY - HH:mm:ss")}</Date>
              <ItemContent>{handleProductName(note.message)}</ItemContent>
            </Item>
          ))
        ) : (
          <EmptyMessage full>There are no notifications here yet</EmptyMessage>
        )}
      </NotesBody>
      <Footer>
        <CustomLink to="dashboard/notifications" onClick={hideHandler}>
          See All
        </CustomLink>
      </Footer>
    </Container>
  );
};

NotificationsPopup.propTypes = {
  show: PropTypes.bool,
  hideHandler: PropTypes.func,
};

export default NotificationsPopup;
