import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";

import { validateTrim } from "_helpers";
import { getStatesOptions } from "_helpers/variables";

import CustomMaskField from "components/atomic/molecules/fields/CustomMaskField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

// const FieldsGroupHeader = styled.div`
//   text-align: center;
//   margin: 0 0 24px 0;
// `;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > * {
    flex: 1;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

// const ButtonsGroup = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   gap: 24px;
// `;

const rules = {
  address1: () => ({
    required: "Street Address is a required field",
    validate: validateTrim,
  }),
  address2: () => ({
    // required: "Apt or Suite is a required field",
    validate: validateTrim,
  }),
  state: () => ({
    required: "State is a required field",
    validate: validateTrim,
  }),
  city: () => ({
    required: "City is a required field",
    validate: validateTrim,
  }),
  zip: () => ({
    required: "ZIP is a required field",
    maxLength: {
      value: 5,
      message: "ZIP should be equal 5 digits",
    },
    pattern: {
      value: /^\d{5}$/i,
      message: "Provide ZIP in the required format",
    },
    validate: validateTrim,
  }),
};

const initValues = {
  newAddress: {
    address1: "",
    address2: "",
    state: "",
    city: "",
    zip: "",
    country: "US",
  },
};

// eslint-disable-next-line react/display-name
const AmendmentBusinessAddressForm = forwardRef((props, ref) => {
  const [stateAutocompleteValue, setStateAutocompleteValue] = useState("");

  const handleStateAutocompleteValue = (e) => {
    const { value } = e.target || {};
    setStateAutocompleteValue(value);
  };

  const { handlers } = props;
  const { onSubmit } = handlers;

  const form = useForm({ defaultValues: initValues, reValidateMode: "onChange" });
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit: createSubmitHandler,
  } = form;

  const handleSubmit = createSubmitHandler(onSubmit);

  useImperativeHandle(
    ref,
    () => ({
      submit: () => handleSubmit(),
    }),
    [handleSubmit]
  );

  useEffect(() => {
    if (stateAutocompleteValue) {
      setStateAutocompleteValue("");
      setValue("newAddress.state", stateAutocompleteValue, {
        shouldValidate: true,
      });
    }
  }, [stateAutocompleteValue, setValue]);

  return (
    <>
      <StyledForm ref={ref} onSubmit={handleSubmit}>
        <FieldsGroup>
          <p>New Business Address</p>
          <FieldsGroupContent>
            <TextField
              column
              name="newAddress.address1"
              label="Street Address"
              placeholder="Address..."
              control={control}
              errors={errors}
              rules={rules.address1()}
            />
            <TextField
              column
              name="newAddress.address2"
              label="Apt or Suite"
              placeholder="Apt or Suite..."
              control={control}
              errors={errors}
              rules={rules.address2()}
            />
          </FieldsGroupContent>
          <FieldsGroupContent>
            <TextField
              column
              name="newAddress.city"
              label="City"
              placeholder="City..."
              control={control}
              errors={errors}
              rules={rules.city()}
            />
            <select
              hidden
              id="state"
              name="state"
              autoComplete="address-level1"
              value={stateAutocompleteValue}
              onChange={handleStateAutocompleteValue}
            >
              <option value="" hidden />
              {getStatesOptions(true).map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <SelectField
              column
              name="newAddress.state"
              label="State"
              placeholder="State..."
              isSearchable={!stateAutocompleteValue}
              options={getStatesOptions(true)}
              control={control}
              errors={errors}
              rules={rules.state()}
            />
            <CustomMaskField
              column
              label="Zip"
              placeholder="99999..."
              mask="99999"
              name="newAddress.zip"
              autoComplete="postal-code"
              control={control}
              errors={errors}
              rules={rules.zip()}
            />
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>
    </>
  );
});

export default AmendmentBusinessAddressForm;

AmendmentBusinessAddressForm.propTypes = {
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
};
