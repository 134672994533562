import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "stretch"};
  align-items: center;
  height: 80px;
  width: 100%;
`;

const SettingsHeaderContainer = styled(HeaderContainer)`
  display: flex;
  gap: 20px;
  max-height: 46px;
  min-height: 46px;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 24px;

  @media (max-width: 991px) {
    justify-content: center;
  }

  @media (max-width: 760px) {
  }

  @media (max-width: 450px) {
    gap: 0px;
    justify-content: space-between;
  }
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* min-width: 150px; */
  width: 100%;
  color: ${({ active, theme }) => (active ? theme.colors["cl-main"] : "#667085")};
  border-bottom: ${({ active, theme }) => (active ? "3px solid " + theme.colors["cl-main"] : "3px solid #ffffff00")};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: background-color 0.2s, border-bottom 0.2s;

  &:hover {
    background-color: ${({ active }) => (active ? "#E0EAFF" : "#F2F4F7")};
    border-color: ${({ active, theme }) => (active ? theme.colors["cl-main"] : "#98A2B3")};
  }

  @media (max-width: 991px) {
    text-align: center;
  }
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: inherit;
  height: 100%;
  min-width: 150px;
  max-width: 150px;

  @media (max-width: 991px) {
    min-width: 120px;
    max-width: 120px;
  }

  @media (max-width: 450px) {
    min-width: 90px;
    max-width: 90px;
  }
`;

const SettingsNav = () => {
  const { pathname } = useLocation();

  const parsedPath = pathname.slice(1).split("/") || [];
  const module = parsedPath[parsedPath.length - 1];

  return (
    <SettingsHeaderContainer>
      <LinkStyled to="account">
        <HeaderItem active={module === `account`}>Account Settings</HeaderItem>
      </LinkStyled>
      <LinkStyled to="billing">
        <HeaderItem active={module === `billing`}>Billing</HeaderItem>
      </LinkStyled>
      <LinkStyled to="support">
        <HeaderItem active={module === `support`}>Support</HeaderItem>
      </LinkStyled>
    </SettingsHeaderContainer>
  );
};

export default SettingsNav;
